<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('SupportRouteCreate')"
    @edit="(id) => onEdit('SupportRouteEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'SupportRoutes',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'SupportRoutes',
      tableCaption: 'Маршруты',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Маршруты',
        },
      ],
      tableHeaders: [
        { text: 'Название действия', alias: 'actionName', order: 'actionName' },
        { text: 'Тип заявки', alias: 'gisTypeName', order: 'gisTypeName' },
        {
          text: 'Статус перехода',
          alias: 'initiationStatusMoveName',
          order: 'initiationStatusMoveName',
        },
        { text: 'Исполнитель', alias: 'fio', order: 'fio' },
        { text: 'Передана в пулл', alias: 'isPool', order: 'isPool' },
        { text: '', alias: 'actions', order: '' },
      ],
    };
  },
};
</script>
